// extracted by mini-css-extract-plugin
export var alignCenter = "companies-and-associations-module--alignCenter--qeXuy";
export var anchor = "companies-and-associations-module--anchor--2d3f4";
export var backgroundDarkBlue = "companies-and-associations-module--backgroundDarkBlue--3gJVc";
export var backgroundGreen = "companies-and-associations-module--backgroundGreen--d+Hjk";
export var backgroundWhite = "companies-and-associations-module--backgroundWhite--KYH1e";
export var bannerImage = "companies-and-associations-module--bannerImage--PmuwH";
export var ctaBlock = "companies-and-associations-module--ctaBlock--Sbdmc";
export var grid = "companies-and-associations-module--grid--eutIb";
export var gridBlock = "companies-and-associations-module--gridBlock--N8-+r";
export var inner = "companies-and-associations-module--inner--76SkJ";
export var layer = "companies-and-associations-module--layer--R1sWf";
export var list = "companies-and-associations-module--list--FFWvg";
export var paragraph = "companies-and-associations-module--paragraph--F1ZmY";
export var paragraphShort = "companies-and-associations-module--paragraphShort--0wUwk";
export var paragraphSmall = "companies-and-associations-module--paragraphSmall--HaMJi";
export var raddningsverket = "companies-and-associations-module--raddningsverket--T4pnm";
export var sectionPaddingBottom = "companies-and-associations-module--sectionPaddingBottom--Wbe61";
export var sectionPaddingTop = "companies-and-associations-module--sectionPaddingTop--9Ph4K";
export var spacingTop = "companies-and-associations-module--spacingTop--wzURb";
export var staticImage = "companies-and-associations-module--staticImage--PQyIQ";
export var subheading = "companies-and-associations-module--subheading--H71Lu";
export var subheadingShort = "companies-and-associations-module--subheadingShort--OYCT8";
export var textBlack = "companies-and-associations-module--textBlack--oxKx7";
export var textCenter = "companies-and-associations-module--textCenter--XRwQj";
export var textDarkblue = "companies-and-associations-module--textDarkblue--4IrAv";
export var textWhite = "companies-and-associations-module--textWhite--ImUYa";
export var twoColGrid = "companies-and-associations-module--twoColGrid--CRfhn";
import { PageProps } from "gatsby";
import React, { useState } from "react";
import { TranslationService } from "../../../services/translation.service";
import { TranslationProvider } from "@/src/contexts/translation.context";
import { Helmet } from "react-helmet";
import Check from "@/assets/icons/check.inline.svg";
import Lr from "@/assets/icons/lr.inline.svg";

import Header from "@/src/components/header/header";
import * as styles from "./companies-and-associations.module.scss";
import useAuthStatus from "@/src/hooks/auth-status.hook";
import Footer from "@/src/components/footer/footer";
import { StaticImage } from "gatsby-plugin-image";

export default function CompaniesAndAssociationsPage({
  pageContext,
}: PageProps) {
  const [translation] = useState(new TranslationService(pageContext));
  const { isAuthenticated } = useAuthStatus();

  return (
    <>
      <TranslationProvider value={translation}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{translation.translate("privacy_policy.title")}</title>
          <meta
            name="description"
            content={translation.translate("privacy_policy.description")}
          />
          <meta http-equiv="content-language" content={translation.lang} />
          <html className="unauthenticated"></html>
        </Helmet>

        <Header
          unauthenticated={!isAuthenticated}
          pageContext={pageContext}
        ></Header>

        <div className={styles.term__inner}>
          <div className="banner">
            <StaticImage
              src="../../../../assets/images/header2.jpg"
              alt="Background image"
              className={styles.bannerImage}
            />
            <div className={`${styles.layer}`}>
              <div className={`${styles.inner}`}>
                <h1 className={`${styles.textCenter}`}>
                  {translation.translate(
                    "companiesAndAssociations.banner.heading"
                  )}
                </h1>
                <p
                  className={`${styles.paragraph} ${styles.textWhite} ${styles.textCenter}`}
                >
                  {translation.translate(
                    "companiesAndAssociations.banner.subHeading"
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className={`${styles.backgroundGreen} ${styles.gridBlock}`}>
            <div
              className={`${styles.inner} ${styles.sectionPaddingBottom} ${styles.sectionPaddingTop}`}
            >
              <div className={`${styles.grid}`}>
                <div>
                  <h2
                    className={`${styles.subheading} ${styles.subheadingShort}`}
                  >
                    {translation.translate(
                      "companiesAndAssociations.block1.heading1"
                    )}
                  </h2>
                  <ul className={`${styles.list}`}>
                    <li>
                      <Check />
                      {translation.translate(
                        "companiesAndAssociations.block1.bullet1.text"
                      )}
                    </li>
                    <li>
                      <Check />
                      {translation.translate(
                        "companiesAndAssociations.block1.bullet2.text"
                      )}
                    </li>
                    <li>
                      <Check />
                      {translation.translate(
                        "companiesAndAssociations.block1.bullet3.text"
                      )}
                    </li>
                    <li>
                      <Check />
                      {translation.translate(
                        "companiesAndAssociations.block1.bullet4.text"
                      )}
                    </li>
                  </ul>
                </div>
                <StaticImage
                  src="../../../../assets/images/faces.png"
                  alt="Happy face & sad face"
                  className={styles.staticImage}
                />
              </div>
              <div>
                <p className={`${styles.paragraph} ${styles.spacingTop}`}>
                  {translation.translate(
                    "companiesAndAssociations.block1.text"
                  )}
                </p>
                <h2
                  className={`${styles.subheading} ${styles.spacingTop} ${styles.subheadingShort} ${styles.spacingTop}`}
                >
                  {translation.translate(
                    "companiesAndAssociations.block1.heading2"
                  )}
                </h2>
              </div>
            </div>
          </div>

          <div
            className={`${styles.sectionPaddingTop} ${styles.backgroundWhite}`}
          >
            <div className={styles.inner}>
              <h2 className={`${styles.subheading} ${styles.subheadingShort}`}>
                {translation.translate(
                  "companiesAndAssociations.block2.heading"
                )}
              </h2>
              <p className={styles.paragraph}>
                {translation.translate("companiesAndAssociations.block2.line1")}
              </p>
              <p className={styles.paragraph}>
                {translation.translate("companiesAndAssociations.block2.line2")}
              </p>
            </div>
          </div>

          <div
            className={`${styles.sectionPaddingBottom} ${styles.sectionPaddingTop} ${styles.backgroundWhite}`}
          >
            <div className={styles.inner}>
              <h2 className={styles.subheading}>
                {translation.translate(
                  "companiesAndAssociations.block3.heading"
                )}
              </h2>
              <p className={styles.paragraph}>
                {translation.translate("companiesAndAssociations.block3.line1")}
              </p>
            </div>
          </div>

          <div
            className={`${styles.sectionPaddingBottom} ${styles.sectionPaddingTop} ${styles.backgroundWhite}`}
          >
            <div className={styles.inner}>
              <h2 className={styles.subheading}>
                {translation.translate(
                  "companiesAndAssociations.block4.heading"
                )}
              </h2>
              <p className={styles.paragraph}>
                {translation.translate("companiesAndAssociations.block4.line1")}
              </p>
            </div>
          </div>

          <div
            className={`${styles.sectionPaddingBottom} ${styles.sectionPaddingTop} ${styles.backgroundGreen}`}
          >
            <div className={`${styles.inner} ${styles.twoColGrid}`}>
              <h2 className={`${styles.subheading} ${styles.textDarkblue}`}>
                {translation.translate("landing.cooperate.heading")}:
              </h2>
              <div className={`${styles.alignCenter}`}>
                <Lr />
                <StaticImage
                  src="../../../assets/images/raddningsverket.png"
                  alt="Mariehamns Räddningsverk"
                  className={styles.raddningsverket}
                />
              </div>
            </div>
          </div>

          <div className={`${styles.backgroundDarkBlue} ${styles.ctaBlock}`}>
            <div
              className={`${styles.inner} ${styles.sectionPaddingBottom} ${styles.sectionPaddingTop}`}
            >
              <h2
                className={`${styles.subheading} ${styles.textCenter} ${styles.textWhite}`}
              >
                {translation.translate(
                  "companiesAndAssociations.block5.heading"
                )}
              </h2>
              <p
                className={`${styles.paragraph} ${styles.textCenter} ${styles.paragraphSmall} ${styles.paragraphShort} ${styles.textWhite} ${styles.spacingTop}`}
              >
                {translation.translate("companiesAndAssociations.block5.line1")}
              </p>
              <a
                className={`${styles.anchor}`}
                href="https://evi.ax/contact#cta-contact-block"
              >
                {translation.translate(
                  "companiesAndAssociations.block5.button"
                )}
              </a>
            </div>
          </div>

          <Footer pageContext={pageContext}></Footer>
        </div>
      </TranslationProvider>
    </>
  );
}
